import Aos from 'aos'
import "aos/dist/aos.css"
import { useEffect } from 'react';
import myPicture from '../../pictures/dhruv_nihalani.png'


function About() {
  useEffect(() => {
    Aos.init({ duration: 1650, once: true })
  })
  return (
    <div>
      <div className="hero min-h-fit mt-10" id="about">
        <div className="hero-content flex-col lg:flex-row">
          <img data-aos="fade-up"
            className="m-20"
            width="500"
            src={myPicture}
            alt="Personal Picture"
          />
          <div data-aos="fade-up">
            <h1 className="text-3xl lg:text-5xl md:text-4xl sm:text-3xl font-bold">
              About Me
            </h1>
            <p className="text-lg py-6">
              Hey! My name is Dhruvesh Nihalani, and this is my portfolio website. Currently, I am a second-year Computer Science student at the University of Guelph who has a passion for software development and problem-solving along with a strong work ethic. I am an aspirational and enthusiastic software engineer with fundamental knowledge of software design and development. I am passionate about learning and problem-solving techniques with fundamental knowledge of Python, Java, and Javascript. I have strong team leadership skills gained by effectively leading and communicating with team members to achieve project goals. <br />
              <br />I am interested in web-development, back-end-development, and full-stack development roles. I am also continually expanding my skillset in Python, Java, HTML / CSS, React, and Node.js. Please feel free to reach me at dnihalan@uoguelph.ca.
            </p>

            {/*
            <button className=" btn btn-lg justify-center items-center btn-secondary-content">
              Resume
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-5 -5 24 24"
                width="24"
                fill="currentColor"
              >
                <path d="M8 6.641l1.121-1.12a1 1 0 0 1 1.415 1.413L7.707 9.763a.997.997 0 0 1-1.414 0L3.464 6.934A1 1 0 1 1 4.88 5.52L6 6.641V1a1 1 0 1 1 2 0v5.641zM1 12h12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2z"></path>
              </svg>
            </button>
            */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About